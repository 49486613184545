.featured {
    width: 100%;
    height: 19%;
    display: flex;
    justify-content: space-between;
}

.featuredItem {
    flex: 1;
    margin: 1% 2% 0% 2%;
    padding: 1.3%;
    border-radius: 10px;
    -webkit-box-shadow: -5px 5px 8px 5px #D0D1CA; 
    box-shadow: -5px 5px 8px 5px #D0D1CA; /*#6cbceb*/
}

.featuredTitle {
    font-size: 1.3vw;
}

.featuredBoxContainer {
    margin: 4% 0%;
    display: flex;
    align-items: center;
}

.featuredNum {
    font-size: 2.1vw;
    font-weight: bold;
}

.featuredRate {
    display: flex;
    align-items: center;
    margin-left: 7%;
    font-size: 1.0vw;
    color: #34046c;
}

.featuredPrevious {
    margin-left: 35%;
    font-size: 1.3vw;
    color: #696969;
}

.featuredSubPrev {
    font-size: 0.86vw;
    margin-left: 25%;
    color: #696969;
}

.featuredSubtitle {
    font-size: 0.86vw;
    white-space: nowrap;
    color: #696969;
}

.featuredNumMillion {
    font-size: 2.0vw;
    font-weight: bold;
    white-space: nowrap;
}

.featuredPreviousMillion {
    margin-left: 20%;
    font-size: 1.2vw;
    color: #696969;
    white-space: nowrap;
}

.featuredSubPrevMillion {
    margin-left: 42%;
    color: #696969;
    white-space: nowrap;
}

.featuredSubPrev2 {
    margin-left: 39%;
    font-size: 0.83vw;
    color: #696969;
}

.featuredPreviousDesc {
    margin-left: 25%;
    font-size: 1.2vw;
    color: #34046c;
}

.featuredPreviousVax {
    margin-left: 35%;
    font-size: 1.3vw;
    color: #34046c;
}

.featuredTitleFAQ {
    font-size: 1.3vw;
    font-weight: bold;
}

.featuredDesc {
    font-size: 0.8vw;
    color: #696969;
}

.featuredItemSymptoms {
    flex: 1;
    margin: 1% 2% 2.5% 2%;
    padding: 1.3%;
    border-radius: 10px;
    -webkit-box-shadow: -5px 5px 8px 5px #D0D1CA; 
    box-shadow: -5px 5px 8px 5px #D0D1CA;
}