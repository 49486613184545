.Sidebar {
    flex: 1;
    height: auto;
    background-color: #e3e3e3; /*#f0f0f0*/
    position: sticky;
}

.SidebarWrapper {
    padding: 6%;
    color: #696969;
}

.SidebarMenu {
    margin-bottom: 20%;
}

.SidebarTitle {
    font-size: 170%;
    color: #9b9b9b; /*#a9a9a9*/
}

.SidebarList {
    list-style: none;
    padding: 1.5%;
}

.SidebarListItem {
    padding: 5%;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    font-size: 125%;
}

.SidebarListItem:hover{
    background-color: #b1d4f5;
}

.SidebarIcon {
    margin-right: 5px;
    font-size: 25px !important;
}

/* make new sections to put bubble on messages */

.sidebarIconBadge{
    width: 5%;
    height: 10%;
    position: relative;
    top: -10px;
    right: 12px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    font-size: 10px;
    align-items: center;
}