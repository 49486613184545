.Topbar{
    width: 100%;
    height: 70px;
    background-color: #870202;
    position: sticky;
    top: 0;
    z-index: 999; /* components will not overlap with top bar */
}

.topbarWrapper{
    height: 70px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo{
    font-weight: bold;
    font-size: 30px;
    color: white;
    cursor: pointer;
    display: grid;  /*move to middle*/
    place-items: center;
}

.topRight{
    display: flex;
    align-items: center;
}

.topbarIcons{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: WHITE;
}

.topPFP{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
}