.box{
	padding: 30px;
}
.emptyBox{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    color: #666;
    width: 100%;
    background: #fff;
}

.box2 {
    padding: 30px;
    width: 70%;
    margin-left: 13%;
}
